@import 'themes-vars';
@import 'plugins/plugins';

/* breadcrumb */
.link-breadcrumb {
  font-size: 0.75rem;
  display: flex;
  text-decoration: none;
}

/* card */

.card-header {
  font-weight: 600 !important;
  font-size: 15px;
}

.apexcharts-legend-marker {
  margin-right: 8px !important;
}

//Pagination li
.css-lpqf0x-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #000000 !important;
  color: #fff !important;
}

//Table Head Style
.css-310vwv-MuiTableCell-root {
  background-color: #000000 !important;
  color: #fff !important;
}
.css-1ouclyo-MuiTableCell-root {
  background-color: #000000 !important;
  color: #fff !important;
}

//Table collaps row
.css-1gsj6qz-MuiTableRow-root {
  // background-color: #778899 !important;
  background-color: #f0f2f8 !important;
  color: #000 !important;
}